import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AdminLayout from './layouts/Admin';
import AuthLayout from './layouts/Auth';

import Login from './pages/Login';

import Main from './pages/Main';

/* eslint-disable react/jsx-props-no-spreading */
const RouteWrapper = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      exact
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default function Routes() {
  return (
    <Switch>
      <RouteWrapper exact path="/login" component={Login} layout={AuthLayout} />
      <RouteWrapper exact path="/" component={Main} layout={AdminLayout} />
    </Switch>
  );
}
