import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export default {
  getApiInstance() {
    return api;
  },

  async login(login, password) {
    const data = { login, password };

    const response = await api.post('/login', data).then(null, (error) => {
      return error.response;
    });

    return response;
  },

  async getWrokerTypes() {
    const response = await api.get('/workers/types').then(null, (error) => {
      return error.response;
    });

    return response;
  },

  async getWrokers(workerTypesId) {
    const response = await api
      .get('/workers', {
        headers: {
          workerTypesId,
        },
      })
      .then(null, (error) => {
        return error.response;
      });

    return response;
  },

  async getRoutes(requestDate, workersId) {
    const response = await api
      .get('/routes', {
        headers: {
          requestDate,
          workersId,
        },
      })
      .then(null, (error) => {
        return error.response;
      });

    return response;
  },

  async getTrackings(requestDate, workersId) {
    const response = await api
      .get('/trackings', {
        headers: {
          requestDate,
          workersId,
        },
      })
      .then(null, (error) => {
        return error.response;
      });

    return response;
  },
};
