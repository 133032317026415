import React, { useState } from 'react';

import { Form, Button, Spinner } from 'react-bootstrap';
import { useAuth } from '../../context/AuthContext';
import logo from '../../assets/images/logo512.png';

function Login() {
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    await signIn(login, password);
    setLoading(false);
  }

  return (
    <div className="row justify-content-center align-content-center">
      <div className="col-lg-8">
        <div className="card p-4">
          <div className="card-header text-center border-bottom border-primary">
            <a href="index.html">
              <span>
                <img src={logo} alt="" height="80" />
              </span>
            </a>
          </div>

          <div className="card-body pt-4 pb-4">
            <div className="text-center w-75 m-auto">
              <h4 className="text-primary text-center mt-0 font-weight-bold">
                Acessar conta
              </h4>
              <p className="text-muted mb-4">
                Digite seu usuário e senha para acessar sua conta.
              </p>
            </div>

            <Form onSubmit={(e) => handleSubmit(e)}>
              <Form.Group>
                <Form.Label>Login</Form.Label>
                <Form.Control
                  placeholder="Digite seu usuário"
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  placeholder="Digite sua senha"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Button type="submit" variant="primary" className="col-12">
                  {loading ? <Spinner animation="border" /> : 'Entrar'}
                </Button>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
