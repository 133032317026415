import React from 'react';

import { useAuth } from '../../context/AuthContext';
import userPhoto from '../../assets/images/users/icon-user.png';

function Header() {
  const { user, signOut } = useAuth();
  return (
    <div className="navbar-custom">
      <ul className="list-unstyled topbar-right-menu float-right mb-0">
        <li className="dropdown notification-list">
          <a
            className="nav-link dropdown-toggle nav-user arrow-none mr-0"
            data-toggle="dropdown"
            href="/"
            role="button"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <span className="account-user-avatar">
              <img src={userPhoto} alt="user" className="rounded-circle" />
            </span>
            <span>
              <span className="account-user-name">{user.name}</span>
              <span className="account-position">Administrador</span>
            </span>
          </a>
          <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
            <div className=" dropdown-header noti-title">
              <h6 className="text-overflow m-0">Bem vindo !</h6>
            </div>

            {/* <a href="/" className="dropdown-item notify-item">
              <i className="mdi mdi-account-circle mr-1" />
              <span>Minha conta</span>
            </a> */}

            {/* <a href="/" className="dropdown-item notify-item">
              <i className="mdi mdi-account-edit mr-1" />
              <span>Configurações</span>
            </a> */}

            {/* <a href="/" className="dropdown-item notify-item">
              <i className="mdi mdi-lifebuoy mr-1" />
              <span>Suporte</span>
            </a> */}

            {/* <a href="/" className="dropdown-item notify-item">
              <i className="mdi mdi-lock-outline mr-1" />
              <span>Lock Screen</span>
            </a> */}

            <button
              type="button"
              onClick={() => signOut()}
              className="dropdown-item notify-item"
            >
              <i className="mdi mdi-logout mr-1" />
              <span>Sair</span>
            </button>
          </div>
        </li>
      </ul>
      {/* <button
        type="button"
        className="button-menu-mobile open-left disable-btn"
      >
        <i className="mdi mdi-menu" />
      </button> */}
    </div>
  );
}

export default Header;
