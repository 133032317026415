import React, { createContext, useCallback, useContext, useState } from 'react';

import { toast } from 'react-toastify';

import { useHistory } from 'react-router-dom';

import api from '../services/api';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const storagePrefix = '@A2Rastreamento';
  const history = useHistory();
  const [data, setData] = useState(() => {
    const token = localStorage.getItem(`${storagePrefix}:token`);
    const user = localStorage.getItem(`${storagePrefix}:user`);
    const company = localStorage.getItem(`${storagePrefix}:company`);

    if (token && user) {
      api.getApiInstance().defaults.headers.Authorization = `${token}`;
      return {
        token,
        user: JSON.parse(user),
        company: JSON.parse(company),
      };
    }

    return {};
  });

  const signIn = useCallback(async (login, password) => {
    try {
      const response = await api.login(login, password);

      const { success, error, data: responseData } = response.data;
      const { token, company_id: id, name, company } = responseData;
      const user = { id, name, login };

      if (!success) {
        toast.error(error);
        return;
      }

      api.getApiInstance().defaults.headers.Authorization = `${token}`;
      localStorage.setItem(`${storagePrefix}:token`, token);
      localStorage.setItem(`${storagePrefix}:user`, JSON.stringify(user));
      localStorage.setItem(`${storagePrefix}:company`, JSON.stringify(company));

      setData({ token, user });

      history.push('/');
      history.go(0);
    } catch (error) {
      toast.error('Falha ao fazer login, verifique seus dados.');
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem(`${storagePrefix}:token`);
    localStorage.removeItem(`${storagePrefix}:user`);
    localStorage.removeItem(`${storagePrefix}:company`);

    setData({});
  }, []);

  return (
    <AuthContext.Provider
      value={{ user: data.user, company: data.company, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
