import React, { useState, useEffect, useCallback } from 'react';

import 'react-datepicker/dist/react-datepicker.css';

import DatePicker, { registerLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import { getYear, getMonth, parseISO, format } from 'date-fns';
import MaskedInput from 'react-input-mask';

registerLocale('pt-BR', pt);

function DateInput({ dateFormat = 'dd/MM/yyyy', onChange }) {
  const [selected, setSelected] = useState(new Date());
  const range = (start, end) => {
    return new Array(end - start).fill().map((d, i) => i + start);
  };
  const years = range(1990, getYear(new Date()) + 10, 1);
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const handleChangeDate = useCallback((date) => {
    if (date) {
      if (onChange) onChange(format(date, 'yyyy-MM-dd'));
      setSelected(parseISO(format(date, 'yyyy-MM-dd')));
    } else {
      if (onChange) onChange(null);
      setSelected(null);
    }
  }, []);

  useEffect(() => {
    handleChangeDate(selected);
  }, []);

  // function handleChangeDate(date) {
  //   if (date) {
  //     if (onChange) onChange(format(date, 'yyyy-MM-dd'));
  //     setSelected(parseISO(format(date, 'yyyy-MM-dd')));
  //   } else {
  //     if (onChange) onChange(null);
  //     setSelected(null);
  //   }
  // }

  return (
    <DatePicker
      customInput={<MaskedInput mask="99/99/9999" placeholder="MM/DD/YYYY" />}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button
            type="button"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            {'<'}
          </button>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button
            type="button"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            {'>'}
          </button>
        </div>
      )}
      locale="pt-BR"
      dateFormat={dateFormat}
      className="form-control"
      wrapperClassName="form-control"
      selected={selected}
      onChange={(date) => handleChangeDate(date)}
    />
  );
}

export default DateInput;
