import React from 'react';
import { Spinner } from 'react-bootstrap';

function Loading({ enable }) {
  return (
    enable && (
      <div
        style={{
          height: 'calc(100vh - 70px)',
          background: 'rgba(51, 51, 51, 0.75)',
          zIndex: '1000',
        }}
        className="d-flex justify-content-center align-content-center align-items-center position-absolute w-100  "
      >
        <Spinner animation="border" variant="primary" />
      </div>
    )
  );
}

export default Loading;
