import React, { useEffect } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AuthLayout({ children }) {
  useEffect(() => {
    document.body.classList.add('authentication-bg');
    const scriptVendor = document.createElement('script');
    scriptVendor.src = '/assets/js/vendor.min.js';
    scriptVendor.async = false;
    document.body.appendChild(scriptVendor);

    const scriptApp = document.createElement('script');
    scriptApp.src = '/assets/js/app.min.js';
    scriptApp.async = false;
    document.body.appendChild(scriptApp);
  }, []);

  return (
    <div className="account-pages mt-5 mb-5">
      <div className="container">
        <div className="content">{children}</div>
        <ToastContainer />
        {/* <Footer /> */}
      </div>
    </div>
  );
}

export default AuthLayout;
