import React, { useEffect } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Redirect } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';

// import SideBar from '../components/SideBar';
import Header from '../components/HeaderBar';
// import Footer from '../components/Footer';

function Main({ children }) {
  const { user } = useAuth();

  useEffect(() => {
    const scriptVendor = document.createElement('script');
    scriptVendor.src = '/assets/js/vendor.min.js';
    scriptVendor.async = false;
    document.body.appendChild(scriptVendor);

    const scriptApp = document.createElement('script');
    scriptApp.src = '/assets/js/app.min.js';
    scriptApp.async = false;
    document.body.appendChild(scriptApp);
  }, []);

  return user ? (
    <div className="wrapper">
      {/* <SideBar /> */}
      <div className="content-page">
        <div className="content">
          <Header />
          <div className="container-fluid">{children}</div>
          <ToastContainer />
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  ) : (
    <Redirect to="/login" />
  );
}

export default Main;
