import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import chroma from 'chroma-js';

import { toast } from 'react-toastify';
import DateInput from '../../components/DateInput';
import Loading from '../../components/Loading';
import Maps from '../../components/Maps';
import api from '../../services/api';

/* eslint-disable no-nested-ternary */
const customStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.4).css()
        : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color.alpha(0.4).hex(), 'white') > 2
          ? 'white'
          : 'black'
        : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.9).css()
          : undefined,
      },
    };
  },

  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.9).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: chroma.contrast(data.color, 'white') > 2 ? 'white' : 'black',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: chroma.contrast(data.color, 'white') > 2 ? 'white' : 'black',
    ':hover': {
      backgroundColor: chroma(data.color).darken().hex(),
      color: 'white',
    },
  }),
};
/* eslint-enable no-nested-ternary */

function Main() {
  const [loadingTypes, setLoadingTypes] = useState(true);
  const [loadingWorkers, setLoadingWorkers] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [workerTypes, setWorkerTypes] = useState(null);
  const [workerTypesIds, setWorkerTypesIds] = useState([]);
  const [workerTypesSelected, setWorkerTypesSelected] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [workersIds, setWorkersIds] = useState([]);
  const [workersSelected, setWorkersSelected] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [trackings, setTrackings] = useState([]);

  async function loadWorkerTypes() {
    const response = await api.getWrokerTypes();

    const { success, error, data } = response.data;

    if (!success) {
      return toast.error(error);
    }

    data.map((workerType) => {
      workerType.value = workerType.id;
      workerType.label = workerType.name;
      return true;
    });

    setWorkerTypes(data);
    setLoading(false);
    return setLoadingTypes(false);
  }

  async function loadWorkers(workerTypesId) {
    const response = await api.getWrokers(workerTypesId);

    const { success, error, data } = response.data;

    if (!success) {
      return toast.error(error);
    }

    data.map((worker) => {
      worker.value = worker.id;
      worker.label = worker.name;
      return true;
    });

    setWorkers(data);
    setLoading(false);
    return setLoadingWorkers(false);
  }

  async function loadRoutes(workerId) {
    setLoading(true);
    try {
      const response = await api.getRoutes(selectedDate, workerId);

      const { success, error, data } = response.data;

      if (!success) {
        return toast.error(error);
      }

      setRoutes(data);
    } catch (error) {
      toast.error('Falha ao carregar rotas');
    } finally {
      setLoading(false);
    }
    return true;
  }

  async function loadTrackings(workerId) {
    setLoading(true);
    try {
      const response = await api.getTrackings(selectedDate, workerId);

      const { success, error, data } = response.data;

      if (!success) {
        return toast.error(error);
      }

      setTrackings(data);
    } catch (error) {
      toast.error('Falha ao carregar rastreamentos');
    } finally {
      setLoading(false);
    }
    return true;
  }

  useEffect(() => {
    loadWorkerTypes();
  }, []);

  function handleDateChange(date) {
    setSelectedDate(date);
  }

  function handleWorkerTypesChange(workerType) {
    setWorkerTypesSelected(workerType);

    if (workerType.length === 0) {
      setWorkerTypesIds([]);
      setWorkersIds([]);
      setWorkersSelected([]);
      setWorkers([]);
      setRoutes([]);
      setTrackings([]);
      setLoadingWorkers(true);
      return;
    }

    const ids = workerType.map((value) => value.id);
    setWorkerTypesIds(ids);
    loadWorkers(ids);
  }

  function handleWorkersChange(workersId) {
    setWorkersSelected(workersId);
    if (workersId.length === 0) {
      setWorkersIds([]);
      setRoutes([]);
      setTrackings([]);
      return;
    }

    const ids = workersId.map((value) => value.id);
    setWorkersIds(ids);
  }

  useEffect(() => {
    if (selectedDate && workerTypesIds.length > 0 && workersIds.length > 0) {
      loadRoutes(workersIds);
      loadTrackings(workersIds);
    }
  }, [selectedDate, workerTypesIds, workersIds]);

  return (
    <>
      <Loading enable={loading} />
      <Row className="m-2">
        <Col className="col-md-4 col-12">
          <DateInput
            onChange={(date) => handleDateChange(date)}
            placeholderText="00/00/0000"
            dateValue={new Date()}
          />
        </Col>
        <Col className="col-md-4 col-12">
          <Select
            options={workerTypes}
            isMulti
            isLoading={loadingTypes}
            value={workerTypesSelected}
            onChange={(values) => handleWorkerTypesChange(values)}
          />
        </Col>
        <Col className="col-md-4 col-12">
          <Select
            styles={customStyles}
            options={workers}
            isMulti
            isLoading={loadingWorkers}
            value={workersSelected}
            onChange={(values) => handleWorkersChange(values)}
          />
        </Col>
      </Row>
      <Maps loading={loading} routes={routes} trackings={trackings} />
    </>
  );
}

export default Main;
