import React, { useState } from 'react';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useAuth } from '../../context/AuthContext';

const defaultOptions = [
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];

const containerStyle = {
  width: '100%',
  height: `calc(100vh - 70px - 57px)`,
};

function Maps({ routes, trackings }) {
  const { company } = useAuth();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyABPwEloCjYDIDcePwqhpdUBAHApjQd95w',
  });

  const [tooltipId, setTooltipId] = useState(0);

  return isLoaded && company ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={13}
      center={{
        lat: Number(company?.latitude),
        lng: Number(company?.longitude),
      }}
      options={{ styles: defaultOptions }}
    >
      {routes.map((route) => (
        <Marker
          key={route.id}
          position={{
            lat: Number(route.latitude),
            lng: Number(route.longitude),
          }}
          icon={route.icon}
          onClick={() => setTooltipId(route.id)}
        >
          {tooltipId === route.id && (
            <InfoWindow onCloseClick={() => setTooltipId(0)}>
              <div className="p-2">
                <h5>{route.name}</h5>
                <p>{route.message}</p>
              </div>
            </InfoWindow>
          )}
        </Marker>
      ))}

      {trackings.map((worker) => (
        <Marker
          key={worker.id}
          position={worker.trackings[worker.trackings.length - 1]}
          icon={`https://chart.apis.google.com/chart?chst=d_map_pin_icon&chld=car|${worker.color.replace(
            '#',
            ''
          )}`}
        />
      ))}

      {trackings.map((worker) => (
        <Polyline
          path={worker.trackings}
          options={{
            geodesic: true,
            strokeColor: worker.color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
          }}
        />
      ))}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default Maps;
